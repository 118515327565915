import GGRndm1 from '../assets/GGRndm1.png';
import GoldBox from '../assets/GoldBox.mp4';
import Castle1 from '../assets/Castle1.png';
import Castle2 from '../assets/Castle2.png';
import Castle3 from '../assets/Castle3.png';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import CastleAbi from '../Abis/CastleAbi.json';
import { connectedAccount, } from './Header';
console.log('Connected Account:', connectedAccount);
// const [connectedAccount, setConnectedAccount] = useState(props.connectedAccount)
// ...

const Grem = () => {

    const [mintAmount2, setMintAmount2] = useState(1); // default value is 1

    const [connectedAccount, setConnectedAccount] = useState(null);

    useEffect(() => {
        if (window.ethereum) {
            if (window.ethereum.selectedAddress) {
                setConnectedAccount(window.ethereum.selectedAddress);
            }
        }
    }, []);
    if (window.ethereum) {
        window.ethereum.on('accountsChanged', (accounts) => {
            setConnectedAccount(accounts[0]);
        });
    }
   
    const mint2 = async () => {

        console.log('Connected Account:', connectedAccount)
        if (connectedAccount == null) {
            alert('Please connect your wallet');
            return;
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contractAddress2 = '0x881dC9f60B4Dec6196d9368520F458cdAA8f245c'; 
        const contract2 = new ethers.Contract(contractAddress2, CastleAbi, signer);

        try {
            // let mintAmount2 = 1; // Replace with the amount to mint
            const ethValue = ethers.utils.parseEther((mintAmount2 * 0.02).toString())
            const tx = await contract2.mintForRegular(connectedAccount, mintAmount2, { value: ethValue })
            await tx.wait();
            alert('Mint successful: Mint successful: Congrats! you have minted a GG MysterBox ');
        } catch (error) {
            console.log(error);
            alert('Mint failed: Not enough Eth in your wallet.');
        }
    };


    return (
        <div className='bg-[url("./assets/Burning.png")] max-h-fit bg-cover '>
           
            <div className="flex flex-col items-center justify-center mx-auto md:flex-auto ">
                <h1 className="text-5xl font-extrabold text-center text-gray-300 shadow-lg"> <br /> <br />
                    <span style={{textShadow:"5px 5px #000000"}} className="shadow-lg text-[white] rounded-lg font-bold  ">GremGoyles V2</span>
                    <br></br>
                </h1>
                <div className='flex flex-col items-center justify-around w-4/5 py-4 mx-auto md:flex-row row '>
                    <div className="flex flex-col items-center justify-center my-10 column">
                        
                        <img className='w-full max-w-sm mt-8 rounded-full' src={GGRndm1} alt="ggrandom" />
                        <br></br>
                        <button onClick={mint2} className="shadow-xl mt-5 shadow-black text-white bg-[#273070] hover:bg-[#bdb825] font-bold p-4 text-lg rounded-xl">Mint Now</button>


                        <span className="items-center justify-center mt-8 font-extrabold text-white shadow-lg text-xl">0.02 Eth  </span>

                        <input
                            type="number"
                            value={mintAmount2}
                            onChange={(e) => setMintAmount2(parseInt(e.target.value))}
                            className="p-2 text-lg font-extrabold text-center text-white bg-transparent border-2 rounded-full shadow-lg outline-none border-blue"
                            style={{ width: '5rem', height: '3rem', lineHeight: '2rem' }}
                        />

                    </div>
                 
                </div>

                <div className="lg:col-span-2 ml-5 overflow-auto mr-5 font-extrabold lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">

                  <h1 className="mt-2 text-3xl font-bold text-white tracking-tight sm:text-3xl">The Evolution  - Gremgoyles V2</h1>
                  <p className="mt-6 text-white leading-8">
                    After the resounding success of the original Gremgoyles NFT collection, the Gremgoyles community was eager to embark on a new adventure. The Gremgoyles team, led by King Pinky, announced the launch of Gremgoyles V2, taking their creative vision to the next level.
                  </p>
                  <p className="mt-6 text-white leading-8">
                    Gremgoyles V2, ”The Gathering,” expanded its NFT collection with 6,888 unique Gremgoyles, celebrating vibrant diversity. These evolving creatures sparked immense community excitement. Collection 2 introduced ”Mystery Boxes and Game Packages” with 33 hidden Gremgoyles, promising suspense and rare finds. Game Packages brought the Gremgoyles world to life, enabling interactive gaming and vibrant Discord community interactions. Gremgoyles V2 remained inclusive, without whitelists, fostering a milestone in NFTs, combining diversity, immersive gaming, and an engaged, inclusive community.
                  </p>
                </div>
              </div>
            </div>

            <div className="lg:col-span-2 mt-10  lg:col-start-1 text-white mr-5 ml-7 font-extrabold lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
              <div className="lg:pr-4">
                <div className="max-w-xl text-base  leading-7 lg:max-w-lg">
                  <h1 className="mt-2 mb-2 text-3xl font-semibold bg-slate-800 tracking-tight sm:text-2xl"> GremGoyles Roadmap </h1>
                  <p>Gremgoyles NFT Collection Roadmap spans across 13 months, encompassing several key phases. </p>

                  <ol className="relative my-5  border-brand">
                    <li className="mb-10 ml-6">
                      <span className="absolute flex items-center justify-center w-8 h-8 rounded-full -left-4 ring-4  ring-dark ring-opacity-40 dark:ring-white bg-brand">
                        <span className="font-bold text-white">1</span>
                      </span>
                      <h3 className="ml-2 font-semibold leading-tight">Phase 1:</h3>
                      <p className="ml-2 text-sm">pre-launch preparations focus on community building, website establishment, and OG Gremgoyle holder rewards, including a unique Pixel Game App.</p>
                    </li>
                    <li className="mb-10 ml-6">
                      <span className="absolute flex items-center justify-center w-8 h-8 rounded-full -left-4 ring-4 ring-dark ring-opacity-40 dark:ring-white bg-brand">
                        <span className="font-bold text-white">2</span>
                      </span>
                      <h3 className="ml-2 font-semibold leading-tight">Phase 2:</h3>
                      <p className="ml-2 text-sm">introduces the presale and public sale, with a whitelist presale whitelist and the public sale.</p>
                    </li>
                    <li className="mb-10 ml-6">
                      <span className="absolute flex items-center justify-center w-8 h-8 rounded-full -left-4 ring-4 ring-dark ring-opacity-40 dark:ring-white bg-brand">
                        <span className="font-bold text-white">3</span>
                      </span>
                      <h3 className="ml-2 font-semibold leading-tight">Phase 3:</h3>
                      <p className="ml-2 text-sm">features a week-long minting extravaganza with daily giveaways and community engagement.</p>
                    </li>
                    <li className="mb-10 ml-6">
                      <span className="absolute flex items-center justify-center w-8 h-8 rounded-full -left-4 ring-4 ring-dark ring-opacity-40 dark:ring-white bg-brand">
                        <span className="font-bold text-white">4</span>
                      </span>
                      <h3 className="ml-2 font-semibold leading-tight">Phase 4:</h3>
                      <p className="ml-2 text-sm">brings Mystery Boxes and Game Packages .enticing collectors with exclusive NFTs and customization options.</p>
                    </li>
                    <li className="mb-10 ml-6">
                      <span className="absolute flex items-center justify-center w-8 h-8 rounded-full -left-4 ring-4 ring-dark ring-opacity-40 dark:ring-white bg-brand">
                        <span className="font-bold text-white">5</span>
                      </span>
                      <h3 className="ml-2 font-semibold leading-tight">Phase 5:</h3>
                      <p className="ml-2 text-sm">involves trait bounties with exciting prizes.</p>
                    </li>
                    <li className="mb-10 ml-6">
                      <span className="absolute flex items-center justify-center w-8 h-8 rounded-full -left-4 ring-4 ring-dark ring-opacity-40 dark:ring-white bg-brand">
                        <span className="font-bold text-white">6</span>
                      </span>
                      <h3 className="ml-2 font-semibold leading-tight">Phase 6:</h3>
                      <p className="ml-2 text-sm"> enhances utility through voice-overs, game release, exclusive interviews, developer, and artist services.</p>
                    </li>
                  </ol>
                  <p>Throughout the roadmap, community engagement remains a priority, fostering creativity and growth. Collaboration with experts and transparent communication are central to success in this thriving Gremgoyles ecosystem.</p>
                </div>
              </div>
            </div>


                <br></br>
                <span className='text-white'>© 2023 GremGoyles. All rights reserved</span>
                <br></br>

            </div>
        </div>
    )
}

export default Grem