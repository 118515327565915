import Header from "./components/Header"
import Grem from "./components/Grem"
import Alert from "./components/Alert"
import Loading from "./components/Loading"





const App = () => {
  return (
    <div className="min-h-screen">
      <Header />
      <Grem />
      <Alert/>
      <Loading/>
    </div>
  )
}

export default App
