import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import gglogo from '../assets/gglogo.PNG';
import { GoThreeBars, GoX } from 'react-icons/go';

const Header = () => {

    const [connectedAccount, setConnectedAccount] = useState(null)
    const [network, setNetwork] = useState(null)
    const [open, setOpen] = useState(false)

    function toggleMenu() {
        setOpen(!open)
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }




    async function connectWallet() {
        if (window.ethereum) {
            try {
                await window.ethereum.request({ method: 'eth_requestAccounts' })
                const provider = new ethers.providers.Web3Provider(window.ethereum)
                const signer = provider.getSigner()
                const address = await signer.getAddress()
                const chainId = await provider.getNetwork().then(network => network.chainId)
                setConnectedAccount(address)

                setNetwork(chainId)
            } catch (error) {
                console.error(error)
            }
        } else {
            console.error('No wallet detected')
        }

    }

    async function switchNetwork() {
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const chainId = await provider.getNetwork().then(network => network.chainId)
            if (chainId !== '0x1') {
                alert('Please switch to the mainnet network')
                return
            }
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x1' }]
            })
        } catch (error) {
            console.error(error)
            alert('Failed to switch network')
        }
    }
    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', (accounts) => {
                if (accounts.length > 0) {
                    setConnectedAccount(accounts[0])
                } else {
                    setConnectedAccount(null)
                }
            })
        }


    }, [connectedAccount])
    console.log('Connected Account:', connectedAccount)

    return (
        <>
            <nav className='sticky z-10 top-0 flex items-center justify-between px-4 py-4 m-0 mx-auto font-bold align-text-top md:px20 w-5/5 gradient-bg-gg md:justify-center'>
                <link rel='stylesheet' href='https://fonts.googleapis.com/css2?family=Luxurious+Roman&display=swap'></link>
                <div className='flex flex-row justify-start items-center md:flex-[0.5] flex-initial'>
                    <img className='h-20 cursor-pointer w-50' src={gglogo} alt='logo' />
                </div>
                <ul className='md:flex md:flex-[0.5] text-white font-bold hidden shadow-lg list-none flex-row justify-between items-center flex-initial w-70'>
                    <a href='https://gremgoylesofficial.com' className='mx-4 cursor-pointer'>GG Official</a>
                    <a href='https://gremgoylesmerch.bigcartel.com' className='mx-4 cursor-pointer'>Online Store</a>
                    <a className='mx-4 cursor-pointer'>Community</a>
                    {connectedAccount && (
                        <>
                            <button className='mx-4 cursor-pointer' onClick={switchNetwork}>Mainnet</button>
                            <div className='p-1 border border-purple-500 rounded-md'>
                                <p className='mx-4 text-green-600'>Connected: {connectedAccount.substring(0, 4)}...{connectedAccount.substring(connectedAccount.length - 4)}</p>
                                <p className='mx-4'>Network: {network === 1 ? 'Mainnet' : 'Unknown'}</p>

                            </div>
                        </>
                    )}
                </ul>
                <button className='shadow-xl shadow-black text-white bg-[#273070] hover:bg-[#bdb825] font-bold md:text-lg p-2 rounded-xl ml-10' onClick={connectWallet}>
                    {connectedAccount ? 'Connected' : 'Connect Wallet'}
                </button>

                {/* Mobile menu button*/}
                <button className='inline-flex items-center justify-center p-2 ml-4 rounded-md outline-none md:hidden focus:ring-1 focus:ring-inset focus:ring-white'>
                    {
                        open ? (
                            <GoX color='white' style={{color: 'white'}} className='block w-6 h-6' aria-hidden='true' onClick={toggleMenu} />
                        ) : (
                            <GoThreeBars color='white' style={{color: 'white'}} className='block w-6 h-6' aria-hidden='true' onClick={toggleMenu} />
                        )
                    }
                </button>
            </nav>

            {/* Mobile only menu */}
            <div className={classNames(open ? 'block' : 'hidden', 'md:hidden bg-black')}>
                <div className='px-2 pt-2 pb-3 space-y-1'>
                    <a href='https://gremgoylesofficial.com' className='block mx-4 text-white cursor-pointer'>GG Official</a>
                    <a href='https://gremgoylesmerch.bigcartel.com' className='block mx-4 text-white cursor-pointer'>Online Store</a>
                    <a className='block mx-4 text-white cursor-pointer'>Community</a>
                    {connectedAccount && (
                        <>
                            <button className='mx-4 cursor-pointer' onClick={switchNetwork}>Mainnet</button>
                            <div className='p-1 border border-purple-500 rounded-md'>
                                <p className='mx-4 text-green-600'>Connected: {connectedAccount.substring(0, 4)}...{connectedAccount.substring(connectedAccount.length - 4)}</p>
                                <p className='mx-4'>Network: {network === 1 ? 'Mainnet' : 'Unknown'}</p>

                            </div>
                        </>
                    )}

                </div>
            </div>
        </>
    )

}

export const connectedAccount = null;

export default Header
